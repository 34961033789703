<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ $t("welcome_to_tsc_dashboard") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>

              <div style="margin-top: 30px"></div>

              <v-row>
                <v-col sm="4" cols="12">
                  <v-card
                    class="mx-auto"
                    max-width="600"
                    color="#385F73"
                    dark
                    @click="
                      $router.push({
                        name: 'ManageStudentByTSC',
                      })
                    "
                    style="border-radius: 20px"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h4 mb-1">
                          {{ count }}
                        </v-list-item-title>
                        <v-list-item-title class="text-h6"
                          >Student</v-list-item-title
                        >
                      </v-list-item-content>

                      <v-list-item-avatar tile size="100px">
                        <i
                          class="fas fa-user-graduate fa-4x float-right"
                          style="color: white"
                        ></i>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-divider color="white"></v-divider>
                    <v-card-actions>
                      <span>Manage Student</span>
                      <v-icon size="15px" class="ml-2">
                        mdi-arrow-right-bold
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col sm="4" cols="12">
                  <v-card
                    class="mx-auto"
                    max-width="600"
                    dark
                    color="#1F7087"
                    @click="
                      $router.push({
                        name: 'TscManageLecturer',
                      })
                    "
                    style="border-radius: 20px"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h4 mb-1">
                          {{ lect }}
                        </v-list-item-title>
                        <v-list-item-title class="text-h6"
                          >Lecturer</v-list-item-title
                        >
                      </v-list-item-content>

                      <v-list-item-avatar tile size="100px">
                        <i
                          class="fas fa-chalkboard-teacher fa-4x float-right"
                          style="color: white"
                        ></i>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-divider color="white"></v-divider>
                    <v-card-actions>
                      <span>Manage Lecturer</span>
                      <v-icon size="15px" class="ml-2">
                        mdi-arrow-right-bold
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col sm="4" cols="12">
                  <v-card
                    class="mx-auto"
                    max-width="600"
                    dark
                    color="#952175"
                    @click="
                      $router.push({
                        name: 'ManageTsc',
                      })
                    "
                    style="border-radius: 20px"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h4 mb-1">
                          {{ countUser }}
                        </v-list-item-title>
                        <v-list-item-title class="text-h6"
                          >TSC User</v-list-item-title
                        >
                      </v-list-item-content>

                      <v-list-item-avatar tile size="100px">
                        <i
                          class="fas fa-users fa-4x float-right"
                          style="color: white"
                        ></i>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-divider color="white"></v-divider>
                    <v-card-actions>
                      <span>Manage TSC User</span>
                      <v-icon size="15px" class="ml-2">
                        mdi-arrow-right-bold
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col sm="4" cols="12">
                  <v-card
                    class="mx-auto"
                    dark
                    max-width="600"
                    color="deep-orange darken-1"
                    style="border-radius: 20px"
                    @click="
                      $router.push({
                        name: 'ManageAnnual',
                      })
                    "
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h4 mb-1">
                          {{ annual }}
                        </v-list-item-title>
                        <v-list-item-title class="text-h6"
                          >Annual Score
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar tile size="100px">
                        <i
                          class="fas fa-graduation-cap fa-4x float-right"
                          style="color: white"
                        ></i>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-divider color="white"></v-divider>
                    <v-card-actions>
                      <span>Manage Annual Score</span>
                      <v-icon size="15px" class="ml-2">
                        mdi-arrow-right-bold
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";

import {
  countUser,
  countStudent,
  countLecturer,
  countAnualScore,
} from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data: () => ({
    countUser: [],
    count: [],
    lect: [],
    token: store.getters.getToken,

    annual: [],
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
    isLoading: false,
    myLoading: false,
  }),
  computed: {},
  methods: {
    async listUser() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await countUser(this.token);
        this.countUser = APIResponse.payload.count;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async countStu() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await countStudent(this.token);
        this.count = APIResponse.payload.count;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async coutlect() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await countLecturer(this.token);
        this.lect = APIResponse.payload.count;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async countAnnual() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await countAnualScore(this.token);
        this.annual = APIResponse.payload.total_annual.count;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  components: {
    Loading,
  },
  mounted() {
    this.listUser();
    this.countStu();
    this.coutlect();
    this.countAnnual();
  },
};
</script>
<style scoped>
.v-subheader {
  color: rgb(0, 255, 42) !important;
}
@media (max-width: 576px) {
}
</style>
