var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"25px","font-weight":"550"}},[_vm._v(" "+_vm._s(_vm.$t("welcome_to_tsc_dashboard"))+" ")]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)],1),_c('div',{staticStyle:{"margin-top":"30px"}}),_c('v-row',[_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"20px"},attrs:{"max-width":"600","color":"#385F73","dark":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'ManageStudentByTSC',
                    })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4 mb-1"},[_vm._v(" "+_vm._s(_vm.count)+" ")]),_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("Student")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"100px"}},[_c('i',{staticClass:"fas fa-user-graduate fa-4x float-right",staticStyle:{"color":"white"}})])],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-card-actions',[_c('span',[_vm._v("Manage Student")]),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15px"}},[_vm._v(" mdi-arrow-right-bold ")])],1)],1)],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"20px"},attrs:{"max-width":"600","dark":"","color":"#1F7087"},on:{"click":function($event){return _vm.$router.push({
                      name: 'TscManageLecturer',
                    })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4 mb-1"},[_vm._v(" "+_vm._s(_vm.lect)+" ")]),_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("Lecturer")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"100px"}},[_c('i',{staticClass:"fas fa-chalkboard-teacher fa-4x float-right",staticStyle:{"color":"white"}})])],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-card-actions',[_c('span',[_vm._v("Manage Lecturer")]),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15px"}},[_vm._v(" mdi-arrow-right-bold ")])],1)],1)],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"20px"},attrs:{"max-width":"600","dark":"","color":"#952175"},on:{"click":function($event){return _vm.$router.push({
                      name: 'ManageTsc',
                    })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4 mb-1"},[_vm._v(" "+_vm._s(_vm.countUser)+" ")]),_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("TSC User")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"100px"}},[_c('i',{staticClass:"fas fa-users fa-4x float-right",staticStyle:{"color":"white"}})])],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-card-actions',[_c('span',[_vm._v("Manage TSC User")]),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15px"}},[_vm._v(" mdi-arrow-right-bold ")])],1)],1)],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"20px"},attrs:{"dark":"","max-width":"600","color":"deep-orange darken-1"},on:{"click":function($event){return _vm.$router.push({
                      name: 'ManageAnnual',
                    })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4 mb-1"},[_vm._v(" "+_vm._s(_vm.annual)+" ")]),_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("Annual Score ")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"100px"}},[_c('i',{staticClass:"fas fa-graduation-cap fa-4x float-right",staticStyle:{"color":"white"}})])],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-card-actions',[_c('span',[_vm._v("Manage Annual Score")]),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15px"}},[_vm._v(" mdi-arrow-right-bold ")])],1)],1)],1)],1)],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }